<template>
  <div class="login">
    <div class="login-wrapper">
      <header>
        <h1 class="title">Welcome to Messenger Pigeon</h1>
      </header>
      <Card class="center-form">
        <template #title>
          <img class="logo" :src="Logo" />
        </template>
        <template #subtitle>
          <h2 class="subtitle">Log in using your Habitat/Beehive account</h2>
        </template>
        <template #content>
          <form
            @submit.prevent="submitForm(!v$.$invalid)"
            class="p-inputtext-lg p-input-filled"
          >
            <div class="p-field">
              <span class="p-float-label">
                <InputText
                  id="email"
                  v-model="v$.email.$model"
                  :class="{ 'p-invalid': v$.email.$invalid && submitted }"
                  aria-describedby="email-error"
                />
                <label
                  for="email"
                  :class="{ 'p-error': v$.email.$invalid && submitted }"
                  >Email</label
                >
              </span>
            </div>
            <div class="p-field last-field">
              <span class="p-float-label">
                <InputText
                  id="password"
                  type="password"
                  v-model="v$.password.$model"
                  :class="{ 'p-invalid': v$.password.$invalid && submitted }"
                  aria-describedby="password-error"
                />
                <label
                  for="password"
                  :class="{ 'p-error': v$.password.$invalid && submitted }"
                  >Password</label
                >
              </span>
            </div>
            <p
              v-if="(v$.$invalid || invalidCredentials) && submitted"
              class="error-message error"
            >
              {{ errorMessage }}
            </p>
            <RoundedButton
              text="LOG IN"
              slim
              extrawide
              shadow
              type="submit"
              class="login-button"
            />
            <UnderlinedButton
              text="Forgot password"
              class="forgot-password-button"
            />
          </form>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { dbConstants } from "../assets/constants";
import RoundedButton from "../components/RoundedButton";
import UnderlinedButton from "../components/UnderlinedButton";
import Logo from "../assets/logo/icon.png";
axios.defaults.withCredentials = true;

export default {
  setup: () => ({ v$: useVuelidate() }),
  async beforeCreate() {
    if (await this.db.getSessionData(dbConstants.SESSION_DATA_LOGGED_IN_USER)) {
      this.$router.push("/dashboard");
    }
  },
  props: {
    db: Object,
  },
  components: {
    RoundedButton,
    UnderlinedButton,
  },
  data() {
    return {
      Logo,
      email: "",
      password: "",
      submitted: false,
      invalidCredentials: false,
      errorMessage: "",
    };
  },
  validations() {
    return {
      email: {
        required,
      },
      password: {
        required,
      },
    };
  },
  methods: {
    submitForm(isFormValid) {
      this.submitted = true;
      this.invalidCredentials = false;
      this.errorMessage = "";
      if (!isFormValid) {
        this.errorMessage = "Please enter a valid email and password";
        return;
      }
      let data = {
        email: this.email,
        password: this.password,
      };
      axios
        .post(process.env.VUE_APP_LOGIN_API, data, {
          withCredentials: false,
        })
        .then((response) => {
          if (response.data.error) {
            this.invalidCredentials = true;
            this.errorMessage =
              // "Received error message " + response.data.message;
              "An account with those credentials was not found";
            return;
          }
          if (response.data.user_token) {
            localStorage.setItem("token", response.data.user_token);
          } else {
            localStorage.setItem("token", "token");
          }
          this.onSuccess();
        })
        .catch((errors) => {
          this.invalidCredentials = true;
          if (errors.response) {
            if (errors.response.status === 500) {
              this.errorMessage =
                "An account with those credentials was not found";
            } else {
              this.errorMessage =
                "Received error code " + errors.response.status;
            }
          } else {
            this.errorMessage = errors.toString();
          }
        });
    },
    onSuccess() {
      this.db.updateSessionData(
        dbConstants.SESSION_DATA_LOGGED_IN_USER,
        this.email
      );
      this.$router.push("/dashboard");
    },
  },
};
</script>

<style lang="scss">
.login {
  display: flex;
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: auto;

  .login-wrapper {
    margin: auto;
    padding: 4rem;

    header {
      width: 40rem;
      margin: auto;
      display: flex;
      justify-content: center;

      .title {
        width: 30rem;
        margin: 0;
        margin-bottom: 2.4rem;
      }
    }

    .center-form {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 38rem;

      .p-card-body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 3rem 4rem 4rem;

        .logo {
          width: 5rem;
        }

        .p-card-subtitle {
          .subtitle {
            font-family: $font-default;
            margin: 0;
            font-size: 2rem;
            padding: 0 1rem;
          }
        }

        .p-card-content {
          width: 100%;
          padding: 0;

          form {
            display: flex;
            flex-direction: column;
            align-items: center;

            > * {
              margin-top: 2.4rem;
            }

            .forgot-password-button {
              margin-top: 1rem;
            }

            .p-field {
              align-self: stretch;
            }

            input {
              font-size: 1.6rem;
              width: 100%;
            }

            .last-field {
              margin-bottom: 1.6rem;
            }

            .error-message {
              margin-top: 1rem;
              margin-bottom: 0;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}

.error {
  color: map-get($component-colours, font-colour-error);
}

.error-border {
  border-color: map-get($component-colours, font-colour-error);
}
</style>
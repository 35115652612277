<template>
  <button
    :class="['underlined-button', light ? 'light' : '']"
    :disabled="disabled"
    @click="onClick"
    :type="type"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    onClick: Function,
    text: String,
    type: String,
    light: Boolean,
  },
};
</script>

<style lang="scss">
.underlined-button {
  text-decoration: underline;
  font-size: 1.4rem;
  color: map-get($component-colours, underlined-button-font-colour-dark);
  font-family: $font-secondary;
  background: none;
  padding: 0;

  &:hover {
    color: lighten(
      map-get($component-colours, underlined-button-font-colour-dark),
      30%
    );
  }

  &.light {
    color: map-get($component-colours, underlined-button-font-colour-light);
  }
}
</style>